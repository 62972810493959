import * as serviceWorker from './serviceWorker';

// Dynamic import so that webpack makes the main app code a separate chunk.
// This code split allows to show splash screen while the main code is still being loaded
import("./component/App/BootUp");

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
